import React from "react"

function Hero(props) {
  const style = {
    backgroundImage:
      "linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('/assets/img/hero/reza-namdari-ZgZsKFnSbEA-unsplash.jpg')",
  }

  return (
    <section className="hero mb-4 mb-md-5">
      <div className="hero__wrapper d-flex align-items-center" style={style}>
        <div className="container">
          <div className="hero__text">
            <div className="subtitle">Mi chiamo</div>
            <h1>Marco Mazzocchi</h1>
            <p className="lead">
              Sono uno <span>Sviluppatore Web</span>
            </p>
            {/* <Link
              to="/#contact"
              className="btn btn-outline-light btn--wider mt-4 smoothscroll"
            >
              Hire Me
            </Link> */}
          </div>
          <div className="ui-scroll" id="ui-scroll">
            <small>Scroll</small>
            <span className="scroll-icon">
              <span className="scroll-icon__wheel-outer">
                <span className="scroll-icon__wheel-inner">&nbsp;</span>
              </span>
            </span>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
