import React, {useState, useCallback} from "react"
import {Formik, Field, Form} from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import {
  GoogleReCaptcha
} from 'react-google-recaptcha-v3';

function Contact(props) {
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [submitSucceeded, setSubmitSucceeded] = useState(false);

  const handleReCaptchaVerify = useCallback(
    token => {
      setRecaptchaToken(token);
    },
    [setRecaptchaToken]
  );

  const requiredFieldMessage = 'Questo campo è obbligatorio';

  const initialValues = { name: '', email: '', message: '' };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(requiredFieldMessage), 
    email: Yup.string().email('Indirizzo mail non valido').required(requiredFieldMessage),
    message: Yup.string().required(requiredFieldMessage),
  });

  const onFormSubmit = (values, formikBag) => {
    axios.post('https://getform.io/f/cba78849-6b39-49eb-af99-b221ef88ab72', values)
        .then(response => {
          if(response.status === 200) {
            setSubmitSucceeded(true);
            formikBag.resetForm();
          }
          formikBag.setSubmitting(false);
        });
  };
  
  const resetFormSubmit = () => {
    setSubmitSucceeded(false);
  };

  const Loader = () => (
  <div className="d-flex justify-content-center">
    <div className="spinner-border" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
  );

  const ThankYouMessage = () => (
    <div className="submit-succeeded text-center">
      <p className="lead">Grazie per il messaggio,<br />
      Ti risponderò appena possibile!</p>
      <button className="btn" onClick={resetFormSubmit}>Invia un altro messaggio</button>
    </div>
  );

  return (
    <section className="contact" id="contact">
      <div
        className="contact__image d-flex align-items-center"
        style={{
          backgroundImage:
            "url(/assets/img/contact/janko-ferlic-h9Iq22JJlGk-unsplash.jpg)",
        }}
      >
        <div className="container">
          <div className="contact__wrapper">
            <div className="row justify-content-md-center d-flex">
              <div className="col-md-12">
                <div className="col-md-12 mb-5">
                  <span className="subtitle justify-content-center d-flex">
                    Amo il caffè e condividerlo con gli altri
                  </span>
                  <h2 className="justify-content-center d-flex">
                    Mettiamoci in contatto
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <ul className="contact-methods">
                  <li>
                    <i className="lni-envelope" />
                    <a href="mailto:info@marcomazzocchi.com" title="Invia una mail">Scrivimi</a> &nbsp; a 
                      info@marcomazzocchi.com
                  </li>
                  {/* <li>
                    <i className="lni-phone-handset" /> <a href="#">Call</a>
                    &nbsp;me at +31228115673
                  </li> */}
                  <li>
                    <i className="lni-map" /> <a
                      href="https://it.wikipedia.org/wiki/Piacenza"
                      target="_blank"
                      title="Informazioni su Piacenza"
                    >Mi trovi</a> &nbsp; a 
                      Piacenza
                  </li>
                </ul>
              </div>
            </div>
            
            <div className="row contact-form">
              <div className="col-md-12">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onFormSubmit}>
                  {({ errors, touched, isValid, dirty, isSubmitting }) => (
                    <>
                    { isSubmitting ? 
                      (<Loader />) :
                    (submitSucceeded ? 
                    (<ThankYouMessage />) :
                    (
                      <Form>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <Field
                            className={`form-control ${errors.name && touched.name && 'is-invalid'}`}
                            id="inputName"
                            placeholder="Nome"
                            name="name"
                          />
                          <div className="input-border" />
                          {errors.name && touched.name && (
                            <div className="invalid-feedback">{errors.name}</div>
                          )}
                        </div>
                        <div className="form-group col-md-6">
                          <Field
                            type="email"
                            className={`form-control ${errors.email && touched.email && 'is-invalid'}`}
                            id="inputEmail"
                            placeholder="Email"
                            name="email"
                          />
                          <div className="input-border" />
                          {errors.email && touched.email && (
                            <div className="invalid-feedback">{errors.email}</div>
                          )}
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-12">
                          <Field
                            as="textarea"
                            className={`form-control ${errors.message && touched.message && 'is-invalid'}`}
                            id="textarea"
                            placeholder="Il tuo messaggio..."
                            rows="10"
                            name="message"
                          />
                          <div className="input-border" />
                          {errors.message && touched.message && (
                            <div className="invalid-feedback">{errors.message}</div>
                          )}
                        </div>
                      </div>
                      <div className="form-row mt-5">
                        <div className="errors">

                        </div>
                        <div className="recaptcha-disclaimer">
                        <GoogleReCaptcha
                          action='homepage'
                          onVerify={handleReCaptchaVerify}
                        />
                          This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                        </div>
                        <div className="col-md-12 justify-content-md-center d-flex">
                          <button
                            type="submit"
                            className="btn btn-primary btn--shadow btn--wider"
                            disabled={!dirty || !isValid || recaptchaToken === ''}
                          >
                            Invia Messaggio
                          </button>
                        </div>
                      </div>
                    </Form>
                    ))
                    }
                    </>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact
