import React from "react"

function Services(props) {
  return (
    <div className="services mt-3 mt-md-5">
      <div className="row">
        <div className="col-md-12">
          <h3 className="title-line">I miei obiettivi</h3>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 mb-4 mb-md-0">
          <h5 className="title-line">
            <i className="lni-code" /> Passione
          </h5>
          <p>
            Amo scrivere codice e migliorarmi nel farlo. <br />
            Mi appassiona creare soluzioni digitali che migliorino la qualità
            del lavoro e della vita delle persone.
          </p>
        </div>
        <div className="col-md-4 mb-4 mb-md-0">
          <h5 className="title-line">
            <i className="lni-direction-alt" /> Visione
          </h5>
          <p>
            Credo che le persone abbiano bisogno di riappacificarsi con il mondo
            e con loro stessi. Serve più consapevolezza e collaborazione per
            rendere la terra un posto migliore.
          </p>
        </div>
        <div className="col-md-4 mb-5 mb-md-0">
          <h5 className="title-line">
            <i className="lni-pyramids" /> Missione
          </h5>
          <p>
            Voglio rendere il lavoro per me e i miei colleghi una sfida
            appassionante, fonte di soddisfazione e occasione di crescita
            continua.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Services
