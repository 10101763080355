import React from "react"
import { Link } from "gatsby"

function UserPreview(props) {
  const yearOfWork = new Date().getFullYear() - 2009

  return (
    <div className="row">
      <div className="col-md-3 d-flex align-items-md-center">
        <div className="profile-photo">
          <img src="assets/img/about/about.jpg" alt="Profile picture" />
        </div>
      </div>
      <div className="col-md-1 pb-3 pb-md-0">
        <div className="social-channels">
          <ul>
            <li>
              <a
                href="https://www.linkedin.com/in/marco-mazzocchi-pc/"
                target="_blank"
              >
                <i className="lni-linkedin-original" />
              </a>
            </li>
            <li>
              <a href="https://medium.com/@marco.mazzocchi.pc" target="_blank">
                <i className="lni-medium" />
              </a>
            </li>
            <li>
              <a href="https://github.com/marco-mazzocchi/" target="_blank">
                <i className="lni-github-original" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="col-md-8">
        <div className="bio">
          <span className="subtitle">Di cosa mi occupo</span>
          <h2>Una passione che va oltre il codice</h2>
          <p>
            Lavoro da {yearOfWork} anni come sviluppatore web muovendomi tra
            Piacenza e Milano. <br />
            Spaziando dallo UI e UX design fino a piccole attività
            sistemistiche, ho trovato la mia massima espressione come
            sviluppatore frontend. Mi piace mettere al centro del mio lavoro le
            persone cercando di facilitare il lavoro ai colleghi e sforzandomi
            di realizzare un prodotto di qualità.
            <br />
            Il mio background professionale tocca diverse tecnologie tra cui:
          </p>
          <ul className="list-inline-check">
            <li>Javascript</li>
            <li>React</li>
            <li>VueJS</li>
            <li>Angular</li>
            <li>Go</li>
            <li>PHP</li>
            <li>Python</li>
            <li>HTML</li>
            <li>CSS</li>
          </ul>
          <div className="row mt-0 mt-md-5">
            <div className="col-md-12">
              <a
                className="btn btn-primary btn--shadow mr-3"
                href="/assets/Marco_Mazzocchi-Curriculum_Vitae.pdf"
                target="_blank"
              >
                <i className="lni-download" /> Scarica il mio curriculum
              </a>
              <Link
                to="/#contact"
                className="btn btn-outline-primary btn--shadow smoothscroll"
              >
                <i className="lni-coffee-cup" /> Invitami a bere un caffè
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserPreview
