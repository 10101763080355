import React from "react"

function Certifications(props) {
  return (
    <section className="pricing bg-f bg-f--skewbottom bg-f--secondary pt-5 mt-5 pb-2 mt-md-10 mb-md-10 pt-md-10 pb-md-10" id="pricing">
            <div className="container">
                <div className="row justify-content-md-center d-flex">
                    <div className="col-md-12">
                        <div className="col-md-12 mb-5 mb-2-offset">
                            {/* <span className="subtitle justify-content-center d-flex">Hire me as your freelancer</span> */}
                            <h2 className="justify-content-center d-flex">Certificazioni</h2>
                        </div>
                    </div>
                </div>
                <div className="pricing-tables">
                    <div className="row mb-5 justify-content-center">
                        <div className="col-md-12 col-lg-5">
                            <div className="pricing-table">
                                <span className="subtitle">Certificazioni Agile</span>
                                <div className="price">
                                    <img src="/assets/img/certifications/badge-csm.png" alt="Certified ScrumMaster badge" />
                                </div>
                                <h4 className="title-line">Certified ScrumMaster</h4>
                              <ul>
                                    <li>Adheres to Scrum as it is defined in the Scrum Guide</li>
                                    <li>Facilitates Scrum events and maintains Scrum artifacts</li>
                                    <li>Works to remove team and organizational impediments</li>
                                </ul>
                                <a href="http://bcert.me/sacqiuirz" title="Certified ScrumMaster badgeCert" target="_blank" className="btn btn-primary btn--shadow btn--wider smoothscroll">Visualizza</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
  )
}

export default Certifications
