import React from "react"

function WorkExperiences(props) {
  const experiences = [
    {
      organization: "FT System",
      jobtitle: "Senior Software Developer",
      period: "2020 - presente",
      tasks: ["VueJS", "Angular", "NodeJS", "Go"],
    },
    {
      organization: "DFLabs",
      jobtitle: "Lead Frontend Developer",
      period: "2016 - 2020",
      tasks: ["React", "SASS", "Python", "PHP"],
    },
    {
      organization: "Intuizone",
      jobtitle: "Technical Project Manager",
      period: "2014 - 2016",
      tasks: ["AngularJS", "NodeJS", "PHP"],
    },
    // {
    //   organization: "RCS",
    //   jobtitle: "Senior Frontend Developer",
    //   period: "2014",
    //   tasks: ["SASS", "jQuery"],
    // },
  ]

  return (
    <div className="experiences">
      <div className="row">
        <div className="col-md-12">
          <h3 className="title-line">Esperienza lavorativa</h3>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          {experiences.map((experience, index) => {
            return (
              <div className="experience" key={index}>
                <div className="experience__organization">
                  {experience.organization}
                </div>
                <div className="experience__time">&nbsp;</div>
                <div className="experience__info">
                  <div className="experience__jobtitle">
                    {experience.jobtitle}
                  </div>
                  <div className="experience__period">
                    <small>{experience.period}</small>
                  </div>
                  <ul className="experience__tasks list-inline-check">
                    {experience.tasks.map((task, index) => (
                      <li key={index}>{task}</li>
                    ))}
                  </ul>
                </div>
              </div>
            )
          })}
          <div className="text-center mt-4">
            <a
                className="btn btn-block btn-outline-primary"
                href="/assets/Marco_Mazzocchi-Curriculum_Vitae.pdf"
                target="_blank"
              >
                <i className="lni-download" /> Vedi tutte le esperienze
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WorkExperiences
