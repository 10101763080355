import React, { useEffect } from "react"

function percentageToDegrees(percentage) {
  return (percentage / 100) * 360
}

function SkillGraphs(props) {
  const BarCircle = ({ value, label }) => {
    const leftStyle = {}
    const rightStyle = {}
    if (value > 0) {
      if (value <= 50) {
        leftStyle.transform = "rotate(" + percentageToDegrees(value) + "deg)"
      } else {
        rightStyle.transform = "rotate(180deg)"
        leftStyle.transform =
          "rotate(" + percentageToDegrees(value - 50) + "deg)"
      }
    }

    return (
      <div className="bar bar--circle mb-5">
        <div className="progress" data-value="75">
          <div className="bar-progress">{value}%</div>
          <span className="progress-left">
            <span className="progress-bar border-primary" style={leftStyle}>
              &nbsp;
            </span>
          </span>
          <span className="progress-right">
            <span className="progress-bar border-primary" style={rightStyle}>
              &nbsp;
            </span>
          </span>
        </div>
        <div className="bar-label">{label}</div>
      </div>
    )
  }

  const LinearGraph = ({ value, label }) => (
    <>
      <div className="bar mb-5">
        <span className="bar-label">{label}</span>
        <span className="bar-progress">{value}%</span>
        <div className="progress">
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: value + "%" }}
            aria-valuenow={value}
            aria-valuemin="0"
            aria-valuemax="100"
          />
        </div>
      </div>
    </>
  )

  return (
    <div className="skills mt-5 mt-md-0">
      <div className="row">
        <div className="col-md-12">
          <h3 className="title-line">Le mie capacità</h3>
          <LinearGraph label="Frontend development" value="90" />
          <LinearGraph label="Team Leadership" value="75" />
          <LinearGraph label="UI & UX Design" value="70" />
          <LinearGraph label="Backend development" value="65" />
          <LinearGraph label="Sysadmin & DevOps" value="50" />
        </div>
      </div>
      <div className="row">
        <div className="col-6 col-md-3">
          <BarCircle value={90} label="Collaborazione" />
        </div>
        <div className="col-6 col-md-3">
          <BarCircle value={85} label="Empatia" />
        </div>
        <div className="col-12 col-md-3">
          <BarCircle value={80} label="Self Control" />
        </div>
        <div className="col-12 col-md-3">
          <BarCircle value={75} label="Organizzazione" />
        </div>
      </div>
    </div>
  )
}

export default SkillGraphs
