import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../assets/scss/main.scss"
import Hero from "../components/Hero"
import About from "../components/About"
import LastPost from "../components/LastPost"
import Contact from "../components/Contact"
import Certifications from "../components/Certifications"

class Home extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Marco Mazzocchi - Web Developer" />
        <Hero />
        <About />
        <Certifications />
        <LastPost posts={posts} />
        <Contact />
      </Layout>
    )
  }
}

export default Home

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 3
      filter: { fields: { slug: { regex: "/^((?!parte-[1-9]).)*$/" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD/MM/YYYY")
            title
            category
            cover
          }
        }
      }
    }
  }
`
