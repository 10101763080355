import React from "react"
import WorkExperiences from "./WorkExperiences"
import SkillGraphs from "./SkillGraphs"

function UserGraph(props) {
  return (
    <div className="row mt-5 mt-sm-10">
      <div className="col-md-4">
        <WorkExperiences />
      </div>
      <div className="col-md-8">
        <SkillGraphs />
      </div>
    </div>
  )
}

export default UserGraph
