import React from "react"
import UserPreview from "./About/UserPreview"
import UserGraph from "./About/UserGraph"
import Services from "./About/Services"

function About(props) {
  return (
    <section className="about pt-0 pt-md-10" id="about">
      <div className="container">
        <UserPreview />
        <UserGraph />
        <Services />
      </div>
    </section>
  )
}

export default About
