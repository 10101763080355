import React from "react"
import { Link } from "gatsby"

function LastPost(props) {
  const { posts } = props
  const mainPost = posts[0].node
  const secondPost = posts[1].node
  const thirdPost = posts[2].node

  return (
    <section
      className="blog-featured pt-5 pb-5 mt-md-10 pb-md-10 pt-md-10 bg-f bg-f--skewbottom"
      id="blog-featured"
    >
      <div className="container">
        <div className="row justify-content-md-center d-flex">
          <div className="col-md-12">
            <div className="col-md-12 mb-md-10">
              <span className="subtitle justify-content-center d-flex">
                Cosa mi piace raccontare
              </span>
              <h2 className="justify-content-center d-flex">
                I miei ultimi articoli
              </h2>
            </div>
          </div>
        </div>
        {posts && (
          <div className="row">
            <div className="col-md-12 col-lg-8">
              <Link to={mainPost.fields.slug} className="featured__link">
                <div className="featured__item featured__item--1">
                  <div className="item__image">
                    <img
                      src={mainPost.frontmatter.cover}
                      alt={mainPost.frontmatter.title}
                    />
                  </div>
                  <div className="featured__content">
                    <span className="featured__category">
                      {mainPost.frontmatter.category}
                    </span>
                    <h3 className="featured__title">
                      {mainPost.frontmatter.title}
                    </h3>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-12 col-lg-4">
              <div className="row">
                <div className="col-md-12">
                  <Link to={secondPost.fields.slug} className="featured__link">
                    <div className="featured__item featured__item--2">
                      <div className="item__image">
                        <img
                          src={secondPost.frontmatter.cover}
                          alt={secondPost.frontmatter.title}
                        />
                      </div>
                      <div className="featured__content">
                        <span className="featured__category">
                          {secondPost.frontmatter.category}
                        </span>
                        <h4 className="featured__title">
                          {secondPost.frontmatter.title}
                        </h4>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <Link to={thirdPost.fields.slug} className="featured__link">
                    <div className="featured__item featured__item--3">
                      <div className="item__image">
                        <img
                          src={thirdPost.frontmatter.cover}
                          alt={thirdPost.frontmatter.title}
                        />
                      </div>
                      <div className="featured__content">
                        <span className="featured__category">
                          {thirdPost.frontmatter.category}
                        </span>
                        <h4 className="featured__title">
                          {thirdPost.frontmatter.title}
                        </h4>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="row justify-content-md-center d-flex mt-5">
        <Link to="/blog" className="btn btn-outline-primary btn--shadow">
          <i className="lni lni-archive" /> Sfoglia tutti gli articoli
        </Link>
      </div>
    </section>
  )
}

export default LastPost
